'use client';

import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import {
  ChevronLeft,
  ChevronRight,
  Globe,
  HelpCircle,
  Loader2,
  User,
} from 'lucide-react';
import { useState, useEffect } from 'react';
import { Button } from '../../@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../@/components/ui/dropdown-menu';
import { Input } from '../../@/components/ui/input';
import { Label } from '../../@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '../../@/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../@/components/ui/select';
import { useToast } from '../../hooks/useToast';
import { supabase } from '../../supabaseClient'; // Add this import
import ProfilePictureUpload from '../ProfilePictureUpload';
import { useTranslation } from 'react-i18next';

function isBrowserLanguageJapanese() {
  const userLanguage = navigator.language;
  return userLanguage.startsWith('ja');
}

const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

const retryOperation = (
  operation: Promise<unknown>,
  delay: number,
  retries: number,
): Promise<unknown> =>
  new Promise((resolve, reject) => {
    return operation.then(resolve).catch((reason) => {
      if (retries > 0) {
        return wait(delay)
          .then(retryOperation.bind(null, operation, delay, retries - 1))
          .then(resolve)
          .catch(reject);
      }
      return reject(reason);
    });
  });

export default function Onboarding({
  onSubmit,
}: {
  onSubmit: (data: any) => Promise<unknown>;
}) {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const [step, setStep] = useLocalStorage('onboardingStep', -1);
  const [formData, setFormData] = useLocalStorage('onboardingFormData', {
    name: '',
    birthYear: '2000',
    gender: '',
    profilePicture: null as File | null,
    language: isBrowserLanguageJapanese() ? 'ja' : 'en',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (session?.user?.user_metadata?.full_name) {
        setFormData((prev) => ({
          ...prev,
          name: session.user.user_metadata.full_name,
          profilePicture: session.user.user_metadata.avatar_url || null,
        }));
      }
    };

    fetchSession();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(formData.language);
  }, [formData.language, i18n]);

  const setLanguage = async (lang: 'en' | 'ja') => {
    try {
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError) throw userError;

      if (!user) {
        console.error('No authenticated user found');
        toast.error(t('noAuthenticatedUserFound'));
        return;
      }

      const { error } = await supabase
        .from('profiles')
        .update({ native_language: lang })
        .eq('supabase_user_id', user.id);

      if (error) throw error;

      setFormData((prev) => ({ ...prev, language: lang }));
      i18n.changeLanguage(lang);
      toast.success(t('languageUpdated'));
    } catch (error) {
      console.error('Error updating native language:', error);
      toast.error(t('failedToUpdateLanguage'));
    }
  };

  const updateFormData = (field: string, value: string | File | null) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const nextStep = async () => {
    if (step === -1) {
      // Skip to the first actual step (Name)
      setStep(0);
    } else if (step === steps.length - 1) {
      setIsSubmitting(true); // Start the spinner and disable the button
      try {
        // Submission logic
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (user) {
          const birthday = new Date(parseInt(formData.birthYear), 0, 1)
            .toISOString()
            .split('T')[0];
          const { error } = await supabase
            .from('profiles')
            .update({
              name: formData.name,
              birthday: birthday,
              gender: formData.gender,
              avatar_url: formData.profilePicture,
            })
            .eq('supabase_user_id', user.id);

          if (error) throw error;
        }

        // Introduce an artificial delay
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay

        // Call onSubmit and wait for it to complete
        // await onSubmit(formData);
        // @ts-ignore
        await retryOperation(onSubmit(formData), 1000, 5);

        // Note: We're not setting isSubmitting to false here,
        // as we expect the page to change after onSubmit
      } catch (error) {
        console.error('Error updating profile:', error);
        // Handle error (e.g., show error message to user)
        setIsSubmitting(false); // Only set to false if there's an error
        toast.error(t('operationFailed'));
      }
    } else {
      setStep((prev) => Math.min(prev + 1, steps.length - 1));
    }
  };

  const handleProfilePicture = (url: string) => {
    updateFormData('profilePicture', url);
  };

  const prevStep = () => setStep((prev) => Math.max(prev - 1, -1));
  const skipOnboarding = async () => {
    setIsSkipping(true);
    try {
      // Add a 2-second delay
      await new Promise((resolve) => setTimeout(resolve, 2000));

      await retryOperation(onSubmit(formData), 1000, 5);
    } catch (error) {
      console.error('Error skipping onboarding:', error);
      toast.error(t('failedToSkipOnboarding'));
    } finally {
      setIsSkipping(false);
    }
  };
  const language = formData.language;

  const steps = [
    // Remove the Language step from here
    {
      title: language === 'en' ? 'Name' : '名前',
      component: (
        <NameStep
          formData={formData}
          updateFormData={updateFormData}
          language={language}
        />
      ),
    },
    {
      title: language === 'en' ? 'Birth Year' : '生年',
      component: (
        <BirthYearStep
          formData={formData}
          updateFormData={updateFormData}
          language={language}
        />
      ),
    },
    {
      title: language === 'en' ? 'Gender' : '性別',
      component: (
        <GenderStep
          formData={formData}
          updateFormData={updateFormData}
          language={language}
        />
      ),
    },
    {
      title: language === 'en' ? 'Profile Picture' : 'プロフィール写真',
      component: (
        <ProfilePictureStep
          formData={formData}
          handleProfilePicture={handleProfilePicture}
          language={language}
        />
      ),
    },
  ];

  const getCurrentStep = () => {
    if (step === -1) {
      return null; // Return null for the initial language selection screen
    } else if (step >= 0 && step < steps.length) {
      return steps[step].component;
    }
    return null; // Return null or a default component if step is out of bounds
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-cover bg-center bg-no-repeat w-full"
      style={{
        backgroundImage: 'url("/earth-bg.jpg")',
      }}
    >
      <div className="w-full max-w-2xl backdrop-blur-xl bg-gray-800/30 rounded-2xl shadow-2xl overflow-hidden border border-gray-700 relative">
        <LanguageSelector
          language={formData.language}
          setLanguage={setLanguage}
          disabled={isSkipping || isSubmitting}
        />
        {isSkipping && (
          <div className="absolute inset-0 bg-gray-900/50 flex items-center justify-center z-50">
            <Loader2 className="h-8 w-8 animate-spin text-white" />
          </div>
        )}
        <div className="flex h-[50px] w-full justify-between items-center">
          {step >= 0 && (
            <Button
              onClick={skipOnboarding}
              variant="ghost"
              className="absolute top-4 right-4 text-white hover:bg-gray-700/50"
              disabled={isSkipping || isSubmitting}
            >
              {isSkipping ? (
                <>
                  {t('skipping')}
                  <Loader2 className="ml-2 h-4 w-4 animate-spin" />
                </>
              ) : (
                <>
                  {t('skipOnboarding')}
                  <ChevronRight className="ml-2 h-4 w-4" />
                </>
              )}
            </Button>
          )}
        </div>
        <div className="p-12">
          <h1 className="text-4xl font-bold text-white mb-8 whitespace-pre-line">
            {step === -1 ? t('welcomeToHashiBilingual') : t('welcomeToHashi')}
          </h1>
          {step > -1 && (
            <ProgressBar currentStep={step} totalSteps={steps.length} />
          )}
          <AnimatePresence mode="wait">
            <motion.div
              key={step}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="bg-gray-700/50 rounded-2xl p-8 mb-8"
            >
              {step === -1 ? (
                <LanguageStep language={language} setLanguage={setLanguage} />
              ) : (
                steps[step].component
              )}
            </motion.div>
          </AnimatePresence>
          <div className="flex justify-between items-center">
            <Button
              variant="ghost"
              onClick={prevStep}
              disabled={step === -1 || isSubmitting || isSkipping}
              className="flex items-center text-gray-300 hover:text-white hover:bg-gray-700/50"
            >
              <ChevronLeft className="mr-2 h-4 w-4" />
              {language === 'en' ? 'Back' : '戻る'}
            </Button>
            <Button
              onClick={nextStep}
              className="flex items-center bg-blue-600 text-white hover:bg-blue-700"
              disabled={
                (step === -1 && !language) || isSubmitting || isSkipping
              }
            >
              {isSubmitting ? (
                <>
                  {language === 'en' ? 'Completing...' : '完了中...'}
                  <Loader2 className="ml-2 h-4 w-4 animate-spin" />
                </>
              ) : (
                <>
                  {step === steps.length - 1 ? (
                    language === 'en' ? (
                      'Complete Profile'
                    ) : (
                      'プロフィールを完成'
                    )
                  ) : (
                    <>
                      {language === 'en' ? 'Next' : '次へ'}
                      <ChevronRight className="ml-2 h-4 w-4" />
                    </>
                  )}
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function LanguageSelector({
  language,
  setLanguage,
  disabled,
}: {
  language: string;
  setLanguage: (lang: 'en' | 'ja') => void;
  disabled: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className="absolute top-4 left-4 z-10">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="text-white hover:bg-gray-700/50 flex items-center space-x-2"
            disabled={disabled}
          >
            <Globe className="h-4 w-4" />
            <span>{language === 'en' ? 'English' : '日本語'}</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="start"
          className="bg-gray-800 border border-gray-700"
        >
          <DropdownMenuItem
            onClick={() => setLanguage('en')}
            className={`text-white hover:bg-gray-700 ${language === 'en' ? 'bg-blue-600' : ''}`}
          >
            <Globe className="mr-2 h-4 w-4" />
            English
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setLanguage('ja')}
            className={`text-white hover:bg-gray-700 ${language === 'ja' ? 'bg-blue-600' : ''}`}
          >
            <Globe className="mr-2 h-4 w-4" />
            日本語
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

function LanguageStep({
  language,
  setLanguage,
}: {
  language: string;
  setLanguage: (lang: 'en' | 'ja') => void;
}) {
  const options = [
    { value: 'en', label: 'English', icon: Globe },
    { value: 'ja', label: '日本語', icon: Globe },
  ];

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-white whitespace-pre-line">
        Select your language
        {'\n'}
        言語を選択してください
      </h2>
      <RadioGroup
        value={language}
        onValueChange={(value: 'en' | 'ja') => setLanguage(value)}
        className="grid grid-cols-2 gap-4"
      >
        {options.map((option) => (
          <Label
            key={option.value}
            htmlFor={option.value}
            className={`flex flex-col items-center justify-center p-4 rounded-xl cursor-pointer transition-colors ${
              language === option.value
                ? 'bg-blue-600'
                : 'bg-gray-600/50 hover:bg-gray-500/50'
            }`}
          >
            <RadioGroupItem
              value={option.value}
              id={option.value}
              className="sr-only"
            />
            <option.icon className="w-12 h-12 mb-2 text-white" />
            <span className="text-lg font-medium text-white">
              {option.label}
            </span>
          </Label>
        ))}
      </RadioGroup>
    </div>
  );
}

function ProgressBar({
  currentStep,
  totalSteps,
}: {
  currentStep: number;
  totalSteps: number;
}) {
  // Adjust the progress calculation
  const progress = (currentStep / (totalSteps - 1)) * 100;

  return (
    <div className="w-full bg-gray-600 rounded-full h-1 mb-8">
      <motion.div
        className="bg-blue-500 h-1 rounded-full"
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{ duration: 0.5 }}
      />
    </div>
  );
}

function NameStep({
  formData,
  updateFormData,
  language,
}: {
  formData: any;
  updateFormData: (field: string, value: string) => void;
  language: string;
}) {
  const { t } = useTranslation();
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-white">
        {t('whatShouldWeCallYou')}
      </h2>
      <div className="space-y-2">
        <Label htmlFor="name" className="text-gray-300">
          {language === 'en' ? 'Name' : '氏名'}
        </Label>
        <Input
          id="name"
          placeholder={language === 'en' ? 'John Doe' : '山田太郎'}
          value={formData.name}
          onChange={(e) => updateFormData('name', e.target.value)}
          className="bg-gray-600/50 border-gray-500 text-white placeholder-gray-400"
        />
      </div>
    </div>
  );
}

function BirthYearStep({
  formData,
  updateFormData,
  language,
}: {
  formData: any;
  updateFormData: (field: string, value: string) => void;
  language: string;
}) {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-white">
        {t('toHelpUsTranslate')}
      </h2>
      <div className="space-y-2">
        <Label htmlFor="birthYear" className="text-gray-300">
          {language === 'en' ? 'Birth Year' : '生年'}
        </Label>
        <Select
          value={formData.birthYear}
          onValueChange={(value) => updateFormData('birthYear', value)}
        >
          <SelectTrigger
            id="birthYear"
            className="bg-gray-600/50 border-gray-500 text-white"
          >
            <SelectValue placeholder={t('selectYear')} />
          </SelectTrigger>
          <SelectContent className="bg-gray-700 border-gray-600 max-h-[300px]">
            {years.map((year) => (
              <SelectItem
                key={year}
                value={year.toString()}
                className="text-white hover:bg-gray-600"
              >
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

function GenderStep({
  formData,
  updateFormData,
  language,
}: {
  formData: any;
  updateFormData: (field: string, value: string) => void;
  language: string;
}) {
  const { t } = useTranslation();
  const options =
    language === 'en'
      ? [
          { value: 'male', label: 'Male', icon: User },
          { value: 'female', label: 'Female', icon: User },
          {
            value: 'gender_unspecified',
            label: 'Prefer not to say',
            icon: HelpCircle,
          },
        ]
      : [
          { value: 'male', label: '男性', icon: User },
          { value: 'female', label: '女性', icon: User },
          {
            value: 'gender_unspecified',
            label: '回答しない',
            icon: HelpCircle,
          },
        ];

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-white">
        {t('lastQuestionToHelp')}
      </h2>
      <RadioGroup
        value={formData.gender}
        onValueChange={(value) => updateFormData('gender', value)}
        className="flex flex-wrap justify-center gap-4"
      >
        {options.map((option) => (
          <Label
            key={option.value}
            htmlFor={option.value}
            className={`flex flex-col items-center justify-center p-3 rounded-xl cursor-pointer transition-colors w-24 h-24 ${
              formData.gender === option.value
                ? 'bg-blue-600'
                : 'bg-gray-600/50 hover:bg-gray-500/50'
            }`}
          >
            <RadioGroupItem
              value={option.value}
              id={option.value}
              className="sr-only"
            />
            <option.icon className="w-8 h-8 mb-2" />
            <span className="text-sm font-medium text-white text-center">
              {option.label}
            </span>
          </Label>
        ))}
      </RadioGroup>
    </div>
  );
}

function ProfilePictureStep({
  formData,
  handleProfilePicture,
  language,
}: {
  formData: any;
  handleProfilePicture: (url: string) => void;
  language: string;
}) {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <ProfilePictureUpload
        onAvatarUpdate={handleProfilePicture}
        currentAvatarUrl={formData.profilePicture}
        language={language as 'en' | 'ja'}
        isOnboarding={true}
        onboardingLayout={true}
      />
    </div>
  );
}
