import { ReactNode, useEffect, useRef, useState } from 'react';
import { GoChevronDown } from 'react-icons/go';
import { MdOutlineLanguage } from 'react-icons/md';

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  onSelect: (selection: string) => void;
  placeholder?: string;
  selectedOption?: string;
  Icon?: ReactNode;
  className?: string;
}

const Dropdown = ({
  options,
  onSelect,
  placeholder = 'Select an option',
  selectedOption,
  Icon,
  className,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openUpwards, setOpenUpwards] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Option>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (selectedOption) {
      const selected = options.find(
        (option) => option.value === selectedOption,
      );
      setSelectedItem(selected);
    }
  }, [options, selectedOption]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent): void => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    if (!isOpen) {
      const buttonRect = buttonRef.current?.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - (buttonRect?.bottom || 0);
      const spaceNeeded = options.length * 40; // Assuming each option is roughly 40px high

      setOpenUpwards(spaceBelow < spaceNeeded);
    }

    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setSelectedItem(option);
    setIsOpen(false);
    onSelect(option.value);
  };

  return (
    <div className="relative text-white " ref={dropdownRef}>
      <button
        ref={buttonRef}
        className={`flex items-center gap-5 rounded py-2 px-4 ${className}`}
        onClick={toggleDropdown}
      >
        <div className="flex items-center gap-2">
          {Icon ? Icon : null}
          {selectedItem ? selectedItem.label : placeholder}
        </div>

        <MdOutlineLanguage
          size={20}
          className={`transform duration-500 ease-in-out ${isOpen && 'rotate-180'}`}
        />
      </button>

      {isOpen && (
        <div
          className={`bg-gray-100 w-max max-h-52 overflow-y-auto py-3 rounded shadow-md z-50 ${
            false ? 'absolute bottom-full mb-2' : 'absolute mt-2'
          }`}
        >
          <ul className="leading-10">
            {options.map((option) => {
              return (
                <li
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                  className={`flex items-center cursor-pointer hover:bg-gray-200 px-3 text-black ${selectedItem?.value === option.value && 'bg-gray-300'}`}
                >
                  {option.label}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
