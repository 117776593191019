// Import necessary hooks and components
import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import { Label } from '../@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../@/components/ui/select';
import { useLanguage } from '../context/LanguageContext';
import { supabase } from '../supabaseClient';

interface LanguageSelectorProps {
  onLanguageUpdate?: (newLanguage: string) => void;
}

// Define the LanguageSelector component
const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  onLanguageUpdate,
}) => {
  // Use the useLanguage hook to access and update the current language
  const { language, setLanguage } = useLanguage();
  // State to handle any errors that might occur
  const [error, setError] = useState<string | null>(null);

  // Update languageOptions to be dynamic based on the current language
  const languageOptions = useMemo(() => {
    if (language === 'JA') {
      return [
        { value: 'EN', label: 'English' },
        { value: 'JA', label: '日本語' },
      ];
    } else {
      return [
        { value: 'EN', label: 'English' },
        { value: 'JA', label: '日本語' },
      ];
    }
  }, [language]);

  // Function to handle language change
  const handleLanguageChange = async (newLanguage: string) => {
    console.log('Language change initiated:', newLanguage);
    setError(null);

    try {
      const {
        data: { user },
        error: authError,
      } = await supabase.auth.getUser();
      if (authError)
        throw new Error(`${t('AuthenticationError')}: ${authError.message}`);
      if (!user) throw new Error(t('noAuthenticatedUser'));

      const { data, error } = await supabase
        .from('profiles')
        .update({ native_language: newLanguage })
        .eq('supabase_user_id', user.id);

      setLanguage(newLanguage);
      console.log('Language updated successfully:', newLanguage);

      // After successful update
      if (onLanguageUpdate) {
        onLanguageUpdate(newLanguage);
      }
    } catch (error) {
      console.error('Error in handleLanguageChange:', error);
      setError(
        error instanceof Error ? error.message : 'An unexpected error occurred',
      );
    }
  };

  // Function to get language label
  const getLanguageLabel = (languageCode: string | null): string => {
    if (!languageCode) return t('selectLanguage');
    const option = languageOptions.find((opt) => opt.value === languageCode);
    return option ? option.label : t('selectLanguage');
  };

  // If language is not yet loaded, don't render anything
  if (!language) {
    return null;
  }

  // Render the language selector dropdown
  return (
    <div className="language-selector">
      {error && <div className="error-message">{error}</div>}

      <div className="space-y-6 mb-5">
        <div className="space-y-2">
          <Label htmlFor="Native Language" className="text-gray-300">
            {t('nativeLanguage')}
          </Label>
          <Select
            value={language}
            onValueChange={(value) =>
              // setProfile({ ...profile, gender: value })
              handleLanguageChange(value)
            }
          >
            <SelectTrigger
              id="nativeLanguage"
              className="bg-gray-600/50 border-gray-500 text-white w-min min-w-[160px]"
            >
              <SelectValue placeholder={t('selectNativeLanguage')} />
            </SelectTrigger>
            <SelectContent className="bg-gray-700 border-gray-600">
              <SelectItem
                value="EN"
                className="text-white data-[highlighted]:bg-blue-600 data-[highlighted]:text-white"
              >
                {t('english')}
              </SelectItem>
              <SelectItem
                value="JA"
                className="text-white data-[highlighted]:bg-blue-600 data-[highlighted]:text-white"
              >
                {t('japanese')}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
