import { forwardRef, MouseEventHandler } from 'react';
import { ChannelList, ChannelListProps } from 'stream-chat-react';
import { MessagingChannelListHeader, MessagingChannelPreview } from '../index';
import { useThemeContext } from '../../context';
import React from 'react';

type MessagingSidebarProps = {
  channelListOptions: {
    filters: ChannelListProps['filters'];
    sort: ChannelListProps['sort'];
    options: ChannelListProps['options'];
  };
  onClick: MouseEventHandler;
  onCreateChannel: () => void;
  onPreviewSelect: MouseEventHandler;
  userId: string;
  activeChannelId: string | null;
};

const MessagingSidebar = forwardRef<HTMLDivElement, MessagingSidebarProps>(
  (
    {
      channelListOptions,
      onCreateChannel,
      onPreviewSelect,
      userId,
      activeChannelId,
    },
    ref,
  ) => {
    const { themeClassName } = useThemeContext();

    // Create a filter to show all channels where the user is a member
    const filters = { members: { $in: [userId] } };

    return (
      <div
        className={`str-chat messaging__sidebar ${themeClassName}`}
        id="mobile-channel-list"
        ref={ref}
      >
        <div className="flex flex-col items-start space-y-2 p-4 pr-0">
          <MessagingChannelListHeader onCreateChannel={onCreateChannel} />
        </div>
        <ChannelList
          filters={filters}
          sort={channelListOptions.sort}
          options={channelListOptions.options}
          customActiveChannel={activeChannelId!}
          Preview={(props) => (
            <MessagingChannelPreview {...props} onClick={onPreviewSelect} />
          )}
        />
      </div>
    );
  },
);

export default MessagingSidebar;
