import React, { useState, useCallback, useRef, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import './ProfilePictureUpload.css';
import Button from './shared/Button';
import { CgSpinner } from 'react-icons/cg';
import { Label } from '../@/components/ui/label';
import { Input } from '../@/components/ui/input';
import { Upload, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface ProfilePictureUploadProps {
  onAvatarUpdate: (url: string) => void;
  maxSize?: number;
  currentAvatarUrl: string | null;
  isOnboarding?: boolean;
  language?: 'en' | 'ja';
  onboardingLayout?: boolean;
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
  onAvatarUpdate,
  maxSize = 128,
  currentAvatarUrl,
  isOnboarding = false,
  language = 'en',
  onboardingLayout = false,
}) => {
  const { t } = useTranslation();
  const [avatarUrl, setAvatarUrl] = useState<string | null>(currentAvatarUrl);
  const [isUploading, setIsUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (avatarUrl && imageRef.current) {
      setIsImageLoaded(false);
      if (imageRef.current.complete) {
        setIsImageLoaded(true);
      } else {
        imageRef.current.onload = () => setIsImageLoaded(true);
      }
    } else {
      setIsImageLoaded(false);
    }
  }, [avatarUrl]);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });

  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: Area,
  ): Promise<Blob | null> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    // Set canvas size to 256x256
    canvas.width = 256;
    canvas.height = 256;

    // Calculate scaling factor
    const scale = Math.max(256 / pixelCrop.width, 256 / pixelCrop.height);

    // Calculate dimensions of the cropped area after scaling
    const scaledWidth = pixelCrop.width * scale;
    const scaledHeight = pixelCrop.height * scale;

    // Calculate position to center the image
    const dx = (256 - scaledWidth) / 2;
    const dy = (256 - scaledHeight) / 2;

    // Draw the cropped image scaled to 256x256
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      dx,
      dy,
      scaledWidth,
      scaledHeight,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/png');
    });
  };

  const uploadAvatar = async () => {
    if (!selectedImage || !croppedAreaPixels) return;

    try {
      setIsUploading(true);

      const croppedImage = await getCroppedImg(
        selectedImage,
        croppedAreaPixels,
      );

      if (!croppedImage) {
        throw new Error(t('failedToCropImage'));
      }

      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error(t('noAuthenticatedUserFound'));

      // Create a new File object with the resized image
      const file = new File([croppedImage], 'avatar.png', {
        type: 'image/png',
      });
      const filePath = `${user.id}-${Math.random()}.png`;

      // Upload the resized image
      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      const { data } = supabase.storage.from('avatars').getPublicUrl(filePath);

      if (!data.publicUrl) {
        throw new Error('Failed to get public URL');
      }

      const { error: updateError } = await supabase
        .from('profiles')
        .update({ avatar_url: data.publicUrl })
        .eq('supabase_user_id', user.id);

      if (updateError) {
        throw updateError;
      }

      setAvatarUrl(data.publicUrl);
      onAvatarUpdate(data.publicUrl);

      setShowModal(false);
      setSelectedImage(null);
    } catch (error) {
      console.error(t('errorUploadingAvatar'), error);
      // Add error handling here (e.g., show an error message to the user)
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result as string);
        setShowModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const getTitle = () => {
    if (isOnboarding) {
      return t('uploadProfilePicture');
    }
    return t('profilePicture');
  };

  const getChooseFileText = () => {
    return t('chooseFile');
  };

  const showSpinner = isUploading || (avatarUrl && !isImageLoaded);

  return (
    <div
      className={`flex flex-col items-center justify-center w-full ${onboardingLayout ? 'space-y-6' : ''}`}
    >
      {isOnboarding && (
        <h2 className="text-2xl font-semibold text-white text-center mb-4">
          {t('uploadProfilePicture')}
        </h2>
      )}

      <div
        className="relative flex items-center justify-center w-32 h-32 mx-auto bg-gray-600/50 rounded-full overflow-hidden cursor-pointer mb-4"
        onClick={handleImageClick}
      >
        {avatarUrl ? (
          <>
            <img
              ref={imageRef}
              src={avatarUrl}
              alt={t('profilePreview')}
              className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${
                isImageLoaded && !showSpinner ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={handleImageLoad}
            />
            {showSpinner && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-10">
                <CgSpinner className="animate-spin text-white text-4xl" />
              </div>
            )}
          </>
        ) : (
          <User className="w-12 h-12 text-gray-400" />
        )}
      </div>

      <Input
        id="profile-picture"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
        ref={fileInputRef}
      />

      {!avatarUrl && !isUploading && (
        <div className="flex justify-center mt-4">
          <Label htmlFor="profile-picture" className="cursor-pointer">
            <div className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center">
              <Upload className="w-4 h-4 mr-2" />
              {t('chooseFile')}
            </div>
          </Label>
        </div>
      )}

      {showModal && selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-[90vw] max-w-[600px]">
            <div className="relative h-[50vh] mb-4">
              <Cropper
                image={selectedImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="flex justify-end">
              <Button
                onClick={handleCloseModal}
                className="mr-2 bg-red-500 hover:bg-red-600"
                disabled={isUploading}
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={uploadAvatar}
                disabled={isUploading}
                className={
                  isUploading
                    ? 'bg-blue-400 cursor-not-allowed'
                    : 'bg-blue-500 hover:bg-blue-600'
                }
              >
                {isUploading ? (
                  <>
                    <CgSpinner className="animate-spin text-white text-xl mr-2" />
                    <span>{t('uploading')}</span>
                  </>
                ) : (
                  t('upload')
                )}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePictureUpload;
